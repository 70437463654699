<template>
  <div>
    <div class="sidebar1">
      <div class="box_1"></div>
      <div class="box_2">
        
        <div class="box2_1">
          <div style="height: 100%">
            <perfect-scrollbar style="height: 100%">
              <div style="width: 60px; height: 90px; background-color: #97a1ec; border: 1px solid #333; margin-bottom: 2px;"
                   v-for="i in index" :key="i"
              ></div>
            </perfect-scrollbar>
          </div>
        </div>
        <div class="box2_2" @click="index++"></div>
        <div class="box2_3"></div>
        
      </div>
      <div class="box_3"></div>
<!--      <div class="box1">-->
<!--        <perfect-scrollbar style="max-height: calc(100% - 100px);">-->

<!--        </perfect-scrollbar>-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>

export default {
  name: 'SidebarTest',
  data() {
    return {
      index: 2
    }
  }
};

</script>

<style lang="scss" scoped>

.sidebar1 {
  width: 100px;
  height: 90%;
  margin-left: 50px;
  background-color: #454d5d;
  display: flex;
  flex-direction: column;
  
  .box_1 {
    flex: 0 0 73px;
    background-color: #dc472e;
  }
  
  .box_2 {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: #2CA800;
    overflow: hidden;
    
    .box2_1 {
      background-color: #9C27B0;
      max-height: calc(100% - 70px);
    }
    
    .box2_2 {
      width: 100%;
      flex: 0 0 70px;
      background-color: #1BC0B7;
    }
    
    .box2_3 {
      flex: 0 0 0px;
      margin-top: auto;
    }
    
  }
  
  .box_3 {
    flex: 0 0 73px;
    background-color: red;
  }

}


</style>
